//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CarouselItemVoucherCategory',
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    product: {
      category: Object,
      required: true
    },
    titleComponent: {
      category: [String, Object],
      default: 'h2'
    }
  }
};
