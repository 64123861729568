//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed, ref, watch } from '@nuxtjs/composition-api';
import { transformImage } from '@odyssey/realtainment-api';
import omit from 'lodash/omit';

import { Flicking } from '@egjs/vue-flicking';
import { Arrow } from '@egjs/flicking-plugins';

import '@egjs/vue-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/arrow.css';

export default {
  name: 'SCarousel',
  components: {
    Flicking,
    AppHeadline: () => import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/SectionHeadline'),
    SProductTile: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-product-tile" */ '@/components/UI/molecules/ProductTile'),
    sCarouselItemVoucherCategory: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-carousels" */ './SCarouselItemVoucherCategory'),
    SCarouselItemGeneralLayout: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-carousels" */ './SCarouselItemGeneralLayout'),
    SCarouselItemTestimonial: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-carousels" */ './SCarouselItemTestimonial'),
    SCarouselItemVoucherBenefit: () =>
      import(/* webpackChunkName: "chunk-ui-molecules-carousels" */ './SCarouselItemVoucherBenefit')
  },
  props: {
    component: {
      type: String,
      required: false,
      default: 's-carousel-item-general-layout'
    },
    title: {
      type: String,
      default: 'Inspiration für dich'
    },
    titleLink: {
      type: String,
      required: false
    },
    titleLinkText: {
      type: String,
      required: false
    },
    showAllText: {
      type: String,
      default: 'Alles anzeigen'
    },
    link: {
      type: Object,
      default: () => ({ url: '#' })
    },
    slides: {
      type: Array,
      default: () => []
    },
    products: {
      type: Array,
      default: () => []
    },
    showAll: {
      type: Boolean,
      default: true
    },
    visibleSlidesMobile: {
      type: String,
      default: '1.1'
    },
    visibleSlidesTablet: {
      type: String,
      default: '2.2'
    },
    visibleSlidesDesktop: {
      type: String,
      default: '4'
    },
    defaultIndex: {
      type: Number,
      default: 0
    },
    alignItem: {
      type: String,
      default: 'prev'
    },
    overFlow: {
      type: String,
      default: 'hidden'
    },
    navigation: {
      type: String,
      default: 'top'
    },
    textAlign: {
      type: String,
      default: 'left'
    },
    verticalSlidesMobile: {
      type: Boolean,
      default: false
    },
    slidesDisplay: {
      type: String
    },
    maxWidth: {
      type: String
    },
    id: {
      type: String,
      default: ''
    },
    imageHeightTablet: {
      type: String,
      default: '400',
      required: false
    },
    imageHeightMobile: {
      type: String,
      default: '400',
      required: false
    },
    imageHeightDesktop: {
      type: String,
      default: '400',
      required: false
    },
    fontSizeMobile: {
      type: String,
      required: false
    },
    fontSizeTablet: {
      type: String,
      required: false
    },
    fontSizeDesktop: {
      type: String,
      required: false
    },
    imageHeight: {
      type: String,
      required: false
    },
    fontSize: {
      type: String,
      required: false
    }
  },
  setup(props, { root }) {
    const plugins = ref([]);
    const flicking = ref(null);

    const navigationArr = ref(null);

    const carouselItems = computed(() => (props.slides.length ? props.slides : props.products));

    watch(navigationArr, () => {
      if (props.navigation && props.navigation !== null)
        plugins.value = [new Arrow({ parentEl: navigationArr?.value })];
    });
    const screenSize = computed(() => root.$screen.width);
    const isMobile = computed(() => screenSize.value < 500);
    const isTablet = computed(() => screenSize.value < 1024);
    const isDesktop = computed(() => screenSize.value >= 1024);
    const visibleSlides = computed(() => {
      if (isMobile.value) {
        return props.visibleSlidesMobile;
      } else if (isTablet.value) {
        return props.visibleSlidesTablet;
      } else if (isDesktop) {
        return props.visibleSlidesDesktop;
      }
    });

    const cssVars = computed(() => {
      if (props.maxWidth > 0 && props.maxWidth !== undefined) {
        return { '--max-width': `${props.maxWidth}px` };
      }
    });
    const imageHeightBP = computed(() => {
      if (isMobile.value) {
        return props.imageHeightMobile ? props.imageHeightMobile : '400';
      } else if (isTablet.value) {
        return props.imageHeightTablet ? props.imageHeightTablet : '400';
      } else if (isDesktop) {
        return props.imageHeightDesktop ? props.imageHeightDesktop : '400';
      }
    });

    const fontSizeBP = computed(() => {
      if (isMobile.value) {
        return props.fontSizeMobile ? props.fontSizeMobile : '24';
      } else if (isTablet.value) {
        return props.fontSizeTablet ? props.fontSizeTablet : '24';
      } else if (isDesktop) {
        return props.fontSizeDesktop ? props.fontSizeDesktop : '24';
      }
    });

    return {
      omit,
      transformImage,
      plugins,
      isMobile,
      navigationArr,
      carouselItems,
      flicking,
      visibleSlides,
      cssVars,
      imageHeightBP,
      fontSizeBP,
      isTablet
    };
  }
};
