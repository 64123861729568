//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'SCarouselItemTestimonial',
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    product: {
      type: Object,
      default: () => null
    }
  },
  setup(props) {
    const computedLink = computed(() => props.product?.link?.url);
    return {
      computedLink
    };
  }
};
