//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ref, watch } from '@nuxtjs/composition-api';
import { parse as parseDate, format as formatDate } from 'date-fns';
import de from 'date-fns/locale/de';

import { Flicking } from '@egjs/vue-flicking';
import { Arrow } from '@egjs/flicking-plugins';

import '@egjs/vue-flicking/dist/flicking.css';
import '@egjs/flicking-plugins/dist/arrow.css';

export default {
  name: 'SPressCarousel',
  components: {
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage'),
    Flicking,
    Arrow
  },
  props: {
    mentionsInNews: {
      type: Array,
      default: () => []
    }
  },
  setup() {
    const plugins = ref([]);
    const navigation = ref(null);
    const options = {
      align: 'prev',
      bound: true,
      defaultIndex: 0,
      autoResize: true,
      panelsPerView: 1
    };

    function getImage(item) {
      return item.img.filename?.trim()?.length ? item.img.filename : item.img_url;
    }

    function getFormattedDate(dateString) {
      const date = parseDate(dateString, 'yyyy-MM-dd HH:mm', new Date());
      return formatDate(date, 'd. LLLL yyyy', { locale: de });
    }
    function getItemAttrs(item) {
      switch (item.link.linktype) {
        case 'url':
          return {
            href: item.link.url,
            target: '_blank'
          };
      }

      return { to: `/${item.link.cached_url}/` };
    }

    watch(navigation, () => {
      plugins.value = [new Arrow({ parentEl: navigation.value })];
    });

    return {
      plugins,
      getImage,
      getItemAttrs,
      getFormattedDate,
      options,
      navigation
    };
  }
};
