var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"l-carousel",class:{ 'l-carousel--max-width': _vm.maxWidth > 0 && _vm.maxWidth !== undefined },style:(_vm.cssVars)},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-12"},[_c('div',{staticClass:"l-carousel__header",class:"l-carousel__header"},[_c('div',{staticClass:"l-carousel__title",class:("l-carousel__title--" + _vm.textAlign)},[_c('app-headline',{staticClass:"l-carousel__title__content",class:{ 'l-carousel__title__content--center': _vm.textAlign === 'center' },attrs:{"text":_vm.title || '',"text-link":_vm.titleLinkText,"link":_vm.titleLink,"id":_vm.id}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.navigation !== null && _vm.navigation === 'top'),expression:"navigation !== null && navigation === 'top'"}],ref:"navigationArr",staticClass:"l-carousel__navigation"},[_c('span',{ref:"arrowPrev",staticClass:"flicking-arrow-prev"}),_vm._v(" "),_c('span',{ref:"arrowNext",staticClass:"flicking-arrow-next"})])]),_vm._v(" "),_c('flicking',{staticClass:"flicking-viewport",class:{
          'flicking-viewport--hidden': _vm.overFlow === 'hidden',
          'flicking-viewport--margin-top': _vm.title === ''
        },attrs:{"options":{
          align: _vm.alignItem,
          defaultIndex: _vm.defaultIndex,
          bound: true,
          panelsPerView: _vm.visibleSlides,
          deceleration: 0.0005,
          hideBeforeInit: true,
          preventEventsBeforeInit: true,
          moveType: ['strict', { count: 1 }],
          vertical: (_vm.isMobile && _vm.verticalSlidesMobile) || (_vm.isTablet && _vm.verticalSlidesMobile)
        },"plugins":_vm.plugins}},[((_vm.isMobile && _vm.verticalSlidesMobile) || (_vm.isTablet && _vm.verticalSlidesMobile))?_c('div',_vm._l((_vm.carouselItems),function(slide,i){return _c('ul',{key:i + 'mobile' + 1,staticClass:"flicking-panel",class:{ 'flicking-panel--no-padding-right': _vm.verticalSlidesMobile }},[_c('li',{key:i + 'mobile' + 1},[[_c(slide.component ? 's-' + slide.component : 's-' + _vm.component,{tag:"component",attrs:{"product":slide,"show-carousel":false,"slidesDisplay":_vm.visibleSlides,"image-height":_vm.imageHeightBP,"font-size":_vm.fontSizeBP}})]],2)])}),0):_vm._l((_vm.carouselItems),function(slide,i){return _c('div',{key:i + 1,staticClass:"flicking-panel"},[[_c(slide.component ? 's-' + slide.component : 's-' + _vm.component,{key:i + 1,tag:"component",attrs:{"product":slide,"show-carousel":false,"slidesDisplay":_vm.visibleSlides,"image-height":_vm.imageHeightBP,"font-size":_vm.fontSizeBP}})]],2)}),_vm._v(" "),(_vm.showAll)?_c('div',{staticClass:"l-carousel__show-all flicking-panel"},[_c('a',{staticClass:"l-carousel__show-all-box",attrs:{"href":_vm.link.url}},[_c('div',{staticClass:"l-carousel__show-all-inner"},[_vm._v("\n              "+_vm._s(_vm.showAllText)+"\n            ")])])]):_vm._e()],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }