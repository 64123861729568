//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { computed } from '@nuxtjs/composition-api';

export default {
  name: 'SCarouselItemGeneralLayout',
  components: {
    Chip: () => import(/* webpackChunkName: "chunk-ui-atoms" */ '@/components/UI/atoms/Chip'),
    NuxtTransformImage: () =>
      import(/* webpackChunkName: "chunk-ui-molecules" */ '@/components/UI/molecules/NuxtTransformImage')
  },
  props: {
    product: {
      type: Object,
      default: () => ({})
    },
    slidesDisplay: {
      type: String
    },
    imageHeight: {
      type: String,
      required: false
    },
    fontSize: {
      type: String,
      required: false
    }
  },
  setup(props, { root }) {
    const motiveAmountComputed = computed(() => {
      parseInt(props.product.motiveAmount);
    });

    const isMobile = computed(() => root.$screen.md === false);

    const text = computed(() => props?.product?.textDescription?.content?.[0]?.content?.[0]?.text);

    const isTablet = computed(() => root.$screen.width > 500 && root.$screen.width < 1024);

    return {
      motiveAmountComputed,
      isMobile,
      text,
      isTablet
    };
  }
};
